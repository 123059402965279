import {
  Component,
  OnInit,
} from '@angular/core';
import {
  CommonModule,
  ViewportScroller,
} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {orderBy} from 'lodash';
import {ContentSectionsComponent} from '../../components/content-sections/content-sections.component';
import {HeaderComponent} from '../../components/header/header.component';
import {BackButtonComponent} from '../../components/back-button/back-button.component';
import {PageTitleComponent} from '../../components/page-title/page-title.component';
import {CommonSpacerComponent} from '../../components/common-spacer/common-spacer.component';
import {AppState} from '../../store/store';
import {
  selectDocumentsList,
  selectPreviewMode,
} from '../../store/portal/portal.selectors';
import {IDocumentDto} from '../../store/portal/portal.model';
import {DocumentCardComponent} from '../../components/documnet-card/document-card.component';
import {downloadDocument} from '../../store/portal/portal.actions';
import {SortDirection} from '../../enums/sort-direction';
import {FooterInfoComponent} from '../../components/footer-info/footer-info.component';

@Component({
  selector: 'app-docs',
  imports: [CommonModule, ContentSectionsComponent, HeaderComponent, TranslateModule, BackButtonComponent,
    PageTitleComponent, CommonSpacerComponent, DocumentCardComponent, FooterInfoComponent],
  templateUrl: './docs.component.html',
  styleUrl: './docs.component.scss',
})
export class DocsComponent implements OnInit {

  public additionalDocumentsList: IDocumentDto[];

  public previewMode$ = this.store.select(selectPreviewMode);

  constructor(private store: Store<AppState>, private viewportScroller: ViewportScroller) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  ngOnInit(): void {
    this.store.select(selectDocumentsList).subscribe(result => {
      this.additionalDocumentsList = orderBy(result, ['uploadedAt'], SortDirection.DESC);
    });
  }

  downloadDocument(documentId: string): void {
    this.store.dispatch(downloadDocument({documentId}));
  }

}
