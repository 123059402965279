import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-html-content',
  imports: [CommonModule],
  templateUrl: './html-content.component.html',
  styleUrl: './html-content.component.scss',
})
export class HtmlContentComponent {

  @Input() text: string | null = '';

}
