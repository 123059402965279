import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormWrapperComponent} from '../../components/form-wrapper/form-wrapper.component';

@Component({
  selector: 'app-not-found',
  imports: [CommonModule, FormWrapperComponent, TranslateModule],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {

}
