import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormWrapperComponent} from '../../components/form-wrapper/form-wrapper.component';

@Component({
  selector: 'app-no-access',
  imports: [CommonModule, FormWrapperComponent, TranslateModule],
  templateUrl: './no-access.component.html',
  styleUrl: './no-access.component.scss',
})
export class NoAccessComponent {

}
