import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {Store} from '@ngrx/store';
import {selectPreviewMode} from '../../store/portal/portal.selectors';
import {AppState} from '../../store/store';
import {navigateWithSelfData} from '../../functions/utils';

@Component({
  selector: 'app-back-button',
  imports: [CommonModule, MatButtonModule, TranslateModule],
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss',
})
export class BackButtonComponent {

  public previewMode$ = this.store.select(selectPreviewMode);

  private previewMode: boolean = false;

  constructor(private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  async handleNavigateOneLevelBack(): Promise<void> {
    this.previewMode$.subscribe(mode => {
      this.previewMode = mode;
    });
    if (this.previewMode) {
      await navigateWithSelfData(this.router, this.activatedRoute.queryParams, ['preview']);
    } else {
      const username = (this.activatedRoute?.firstChild || this.activatedRoute)?.snapshot.paramMap.get('username');
      if (username) {
        await this.router.navigate(['/', username]);
      }
    }
  }

}
