import {
  Component,
  Input,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-analytics-footer-info',
  imports: [CommonModule, TranslateModule],
  templateUrl: './analytics-footer-info.component.html',
  styleUrl: './analytics-footer-info.component.scss',
})
export class AnalyticsFooterInfoComponent {

  @Input() iOsMode: boolean = false;

}
