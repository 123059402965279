import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-common-spacer',
  imports: [CommonModule],
  templateUrl: './common-spacer.component.html',
  styleUrl: './common-spacer.component.scss',
})
export class CommonSpacerComponent {
}
