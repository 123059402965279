import {
  Component,
  Input,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {PersonalData} from '../../store/portal/portal.model';
import {Constants} from '../../constants/constants';
import {PasteApostropheAfterEachThreeNumsPipe} from '../../pipes/paste-apostrophe-after-each-three-nums.pipe';

@Component({
  selector: 'app-analytics-header-info',
  imports: [CommonModule, PasteApostropheAfterEachThreeNumsPipe],
  templateUrl: './analytics-header-info.component.html',
  styleUrl: './analytics-header-info.component.scss',
})
export class AnalyticsHeaderInfoComponent {

  @Input() pdfMode: boolean = false;

  @Input() iOsMode: boolean = false;

  @Input() personalData: PersonalData;

  @Input() labels: Record<string, string>;

  protected readonly Constants = Constants;

  constructor(private translateService: TranslateService) {
  }

  getLabel(key: string): string {
    return this.labels?.[key] ? this.translateService.instant(this.labels?.[key]) : '';
  }

}
