import {enableProdMode} from '@angular/core';
import {bootstrapApplication} from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import {appConfig} from './app/app.config';
import {AppComponent} from './app/app.component';
import {environment} from './environments/environment';

Sentry.init({
  environment: environment.name,
  dsn: environment.sentry.dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: environment.sentry.tracesSampleRate,
  replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
  tracePropagationTargets: [environment.portalApiUrl],
});

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
