import {
  Component,
  Input,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  AnalyticDataInfo,
  NumericComparisonMetrics,
  StringComparisonMetrics,
} from '../../store/portal/portal.model';
import {RemarkBottomSheetComponent} from '../remark-bottom-sheet/remark-bottom-sheet.component';
import {Constants} from '../../constants/constants';
import {PasteApostropheAfterEachThreeNumsPipe} from '../../pipes/paste-apostrophe-after-each-three-nums.pipe';
import {RoundPercentagePipe} from '../../pipes/round-percentage.pipe';

enum PensionType {
  FULL_CONTRACT = 'FULL_CONTRACT',
  AUTONOMOUS = 'AUTONOMOUS',
  SAVINGS_RISKS = 'SAVINGS_RISKS',
  INDIVIDUAL = 'INDIVIDUAL',
}

@Component({
  selector: 'app-analytics-table',
  imports: [CommonModule, MatTooltipModule, MatBottomSheetModule, TranslateModule,
    PasteApostropheAfterEachThreeNumsPipe, RoundPercentagePipe],
  templateUrl: './analytics-table.component.html',
  styleUrl: './analytics-table.component.scss',
})
export class AnalyticsTableComponent {

  @Input() pdfMode: boolean = false;

  @Input() iOsMode: boolean = false;

  @Input() rootTranslationKey: string = '';

  @Input() tableData: AnalyticDataInfo;

  @Input() labels: Record<string, string>;

  protected readonly Constants = Constants;

  constructor(
    private bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
  ) {
  }

  getDataKeys(): string[] {
    return Object.keys(this.tableData);
  }

  isPensionInfoData(): boolean {
    return this.getDataKeys().some((key: string) => this.tableData?.[key]
      && !Object.hasOwn(this.tableData[key], 'percent'));
  }

  isEmployeeContributionsPctData(key: string): boolean {
    return key === 'employeeContributionsPct';
  }

  getStringValues(key: string): StringComparisonMetrics {
    return this.tableData?.[key];
  }

  getNumericValue(key: string): NumericComparisonMetrics {
    return this.tableData?.[key];
  }

  isMobileView(): boolean {
    const mobileViewWidth = 480; // Adjust this value based on your mobile breakpoint
    return window.innerWidth < mobileViewWidth;
  }

  openBottomSheet(title: string, contentText: string): void {
    this.bottomSheet.open(RemarkBottomSheetComponent, {
      data: {
        title,
        contentText,
        closeLabel: this.translateService.instant('title.close'),
      },
    });
  }

  getTranslationForBoolean(key: boolean): string {
    return key ? this.getTranslationKeyByKey('diff.changed') : this.getTranslationKeyByKey('diff.unchanged');
  }

  getTranslationValue(key: string): string {
    return Object.keys(PensionType).includes(key)
      ? this.translateService.instant(this.labels?.[`${this.rootTranslationKey}.contractType.${key.toLowerCase()}`])
      : key;
  }

  getTranslationKeyByKey(key: string): string {
    return this.labels?.[key] ? this.translateService.instant(this.labels?.[key]) : '';
  }

  getTranslationTitle(key: string): string {
    return this.labels?.[`${this.rootTranslationKey}${Constants.DOT}${key}`]
      ? this.translateService.instant(this.labels?.[`${this.rootTranslationKey}${Constants.DOT}${key}`]) : '';
  }

  getTranslationForRemark(key: string): string {
    const translationKey = `analytics.${this.rootTranslationKey}${Constants.DOT}${key}.remark`;
    const translatedLabel = this.translateService.instant(translationKey);
    return translatedLabel !== translationKey ? translatedLabel : '';
  }

}
