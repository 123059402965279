import {
  Component,
  Inject,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-remark-bottom-sheet',
  imports: [CommonModule, MatButtonModule],
  templateUrl: './remark-bottom-sheet.component.html',
  styleUrl: './remark-bottom-sheet.component.scss',
})
export class RemarkBottomSheetComponent {

  public title: string = '';

  public contentText: string = '';

  public closeLabel: string = '';

  constructor(
    private bottomSheetRef: MatBottomSheetRef<RemarkBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {title: string, contentText: string, closeLabel: string},
  ) {
    this.title = data.title;
    this.contentText = data.contentText;
    this.closeLabel = data.closeLabel;
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }

}
