const devApiUrl: string = 'https://vp.be.dev.sobrado.ch';

export const environment = {
  name: 'dev',
  production: false,
  publicApiUrl: `${devApiUrl}/api/public`,
  portalApiUrl: `${devApiUrl}`,
  sentry: {
    dsn: 'https://e1233cca559db4353d118472cb579029@o302940.ingest.us.sentry.io/4508992843153408',
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
  },
};
