import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {
  combineLatest,
  map,
} from 'rxjs';
import {AppState} from '../../store/store';
import {
  selectBrokerName,
  selectContactLanguage,
} from '../../store/contact/contact.selectors';
import {selectPortalLanguage} from '../../store/portal/portal.selectors';
import {selectLoggedIn} from '../../store/auth/auth.selectors';

@Component({
  selector: 'app-footer-info',
  imports: [CommonModule, TranslateModule],
  templateUrl: './footer-info.component.html',
  styleUrl: './footer-info.component.scss',
})
export class FooterInfoComponent {

  readonly brokerName$ = this.store.select(selectBrokerName);

  public language$ = combineLatest([
    this.store.select(selectLoggedIn),
    this.store.select(selectPortalLanguage),
    this.store.select(selectContactLanguage),
  ]).pipe(
    map(([loggedIn, portalLanguage, contactLanguage]) => (loggedIn ? portalLanguage : contactLanguage)),
  );

  constructor(private store: Store<AppState>) {
  }

}
