import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  CommonModule,
  ViewportScroller,
} from '@angular/common';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {MatButton} from '@angular/material/button';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {isEmpty} from 'lodash';
import {ContentSectionsComponent} from '../../components/content-sections/content-sections.component';
import {HeaderComponent} from '../../components/header/header.component';
import {BackButtonComponent} from '../../components/back-button/back-button.component';
import {PageTitleComponent} from '../../components/page-title/page-title.component';
import {CommonSpacerComponent} from '../../components/common-spacer/common-spacer.component';
import {AnalyticsHeaderInfoComponent} from '../../components/analytics-header-info/analytics-header-info.component';
import {AnalyticsFooterInfoComponent} from '../../components/analytics-footer-info/analytics-footer-info.component';
import {AnalyticsTableComponent} from '../../components/analytics-table/analytics-table.component';
import {AppState} from '../../store/store';
import {selectPersonalAnalyticsData} from '../../store/portal/portal.selectors';
import {PersonalAnalyticInfo} from '../../store/portal/portal.model';
import {FooterInfoComponent} from '../../components/footer-info/footer-info.component';
import {FileType} from '../../enums/file.enum';
import {Constants} from '../../constants/constants';
import {
  requestError,
  requestSuccess,
} from '../../store/auth/auth.actions';

@Component({
  selector: 'app-analytics',
  imports: [CommonModule, ContentSectionsComponent, HeaderComponent, TranslateModule, BackButtonComponent,
    PageTitleComponent, CommonSpacerComponent, AnalyticsHeaderInfoComponent, AnalyticsFooterInfoComponent,
    AnalyticsTableComponent, MatButton, FooterInfoComponent],
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss',
})
export class AnalyticsComponent implements OnInit {

  @ViewChild('analyticsRef') analyticsRef: ElementRef;

  @ViewChild('analyticsMobileRef') analyticsMobileRef: ElementRef;

  @ViewChild('analyticsModeIOSRef') analyticsModeIOSRef: ElementRef;

  public jsonObject: PersonalAnalyticInfo;

  constructor(
    private store: Store<AppState>,
    private renderer: Renderer2,
    private viewportScroller: ViewportScroller,
    private translateService: TranslateService,
  ) {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  ngOnInit(): void {
    this.store.select(selectPersonalAnalyticsData).subscribe(result => {
      if (!isEmpty(result)) {
        this.jsonObject = JSON.parse(result);
      }
    });
  }

  downloadAsPdfDocument(): void {
    const fileName = this.translateService.instant(
      'document.title.personal_pension_comparion',
      {employeeName: this.jsonObject?.personalData.name},
    ) + Constants.DOT + FileType.PDF;
    let element: HTMLElement;

    if (this.isIOsOrSafari()) {
      element = this.analyticsModeIOSRef?.nativeElement;
      this.renderer.setStyle(element, 'visibility', 'visible');
    } else if (window.innerWidth <= 720) {
      element = this.analyticsMobileRef?.nativeElement;
      this.renderer.setStyle(element, 'visibility', 'visible');
    } else {
      element = this.analyticsRef?.nativeElement;
    }
    // eslint-disable-next-line new-cap
    const pdfDocument: jsPDF = new jsPDF({unit: 'px'});
    html2canvas(element, {scale: 3}).then(canvas => {
      pdfDocument.addImage(canvas, 40, 20, 360, 580);
      pdfDocument.save(fileName);
      this.hideComponentForMobileView(element);
      this.store.dispatch(requestSuccess(
        {message: this.translateService.instant('success.message.file_downloaded_successfully')},
      ));
    }).catch(() => {
      this.store.dispatch(requestError(
        {message: this.translateService.instant('error.message.file_storage_error')},
      ));
      this.hideComponentForMobileView(element);
    });
  }

  private isIOsOrSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      || /iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase());
  }

  private hideComponentForMobileView(element: HTMLElement): void {
    if (window.innerWidth <= 720 || this.isIOsOrSafari()) {
      this.renderer.setStyle(element, 'visibility', 'hidden');
    }
  }

}
