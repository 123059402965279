import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule} from '@angular/forms';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import moment from 'moment';
import {FormWrapperComponent} from '../form-wrapper/form-wrapper.component';
import {formatDateISO} from '../../functions/utils';
import {AlertsService} from '../../services/alerts.service';
import {Constants} from '../../constants/constants';

@Component({
  selector: 'app-voting-form',
  imports: [CommonModule, FormWrapperComponent, MatButtonModule, MatRadioModule, FormsModule, TranslateModule],
  templateUrl: './voting-form.component.html',
  styleUrl: './voting-form.component.scss',
})
export class VotingFormComponent implements OnChanges {

  @Input() voted = false;

  @Input() votedDate = '';

  @Input() voteDeadline = '';

  @Input() deadLineEnabled = false;

  @Input() previewMode = false;

  @Output() decisionMade = new EventEmitter<boolean>();

  public votedDateStr = '';

  public voteDeadlineStr = '';

  public votingDecision: string = '';

  constructor(public alerts: AlertsService, public translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['votedDate']) {
      this.votedDateStr = formatDateISO(changes['votedDate'].currentValue);
    }
    if (changes['voteDeadline']) {
      this.voteDeadlineStr = formatDateISO(this.voteDeadline);
    }
  }

  onDecisionDone(): void {
    if (this.votingDecision) {
      this.decisionMade.emit(this.votingDecision === 'yes');
    } else {
      this.alerts.showInfo({
        statusText: this.translate.instant('voting.no_choice_done_error_type'),
        message: this.translate.instant('voting.please_provide_your_voting_choice'),
      });
    }
  }

  isDeadlinePassed(): boolean {
    return this.deadLineEnabled && moment().utc().isAfter(moment(this.voteDeadline).utc());
  }

  protected readonly Constants = Constants;

}
