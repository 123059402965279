import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxMaskPipe} from 'ngx-mask';
import {Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {AppState} from '../../store/store';
import {selectFooterData} from '../../store/contact/contact.selectors';
import {Constants} from '../../constants/constants';
import {selectPreviewMode} from '../../store/portal/portal.selectors';

@Component({
  selector: 'app-footer-contact',
  imports: [CommonModule, NgxMaskPipe, TranslateModule],
  templateUrl: './footer-contact.component.html',
  styleUrl: './footer-contact.component.scss',
})
export class FooterContactComponent {

  public PHONE_MASK = Constants.SWISS_PHONE_MASK;

  constructor(private store: Store<AppState>) {
  }

  readonly contact$ = this.store.select(selectFooterData);

  public previewMode$ = this.store.select(selectPreviewMode);

}
